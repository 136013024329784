#root {
  padding-bottom: 50px;
}

.circle {
  border-radius: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  color: white;
  align-items: center;
  justify-content: center;
}

.embroidered-badge {
  background-color: purple;
}

.poster-badge {
  background-color: green;
}

.digital-badge {
  background-color: blue;
}

.feedback {
  margin-top: 5px;
  padding: 7px 10px;
  border-radius: 5px;
  background: #f0f0f0;
  white-space: pre-line;
}

.comment {
  margin-left: 30px;
  padding: 7px 10px;
  border-radius: 5px;
  background: #f0f0f0;
  white-space: pre-line;
}

.cardEvent {
  margin-top: 5px;
  white-space: pre-line;
}

.attachment {
  width: 150px;
  height: 120px;
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-register {
  max-width: 800px;
  margin: 100px auto 0;
}

.svg-container svg {
  max-height: 200px;
}

.timeline {
  position: relative;
  width: 85%;
  max-width: 700px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0 15px 15px;
  border-left: 2px solid grey;
}

.timeline-item {
  display: flex;
  justify-content: center;

  flex-direction: column;
}

.timeline-item::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: darkgrey;
  position: absolute;
  left: -7px;
}

.copy,
.edit {
  cursor: pointer;
  float: right;
}
.copy:hover svg,
.edit:hover svg {
  fill: #303030;
}
